<template>
  <v-container class="pa-4 text-center">
    <v-row>
      <v-col cols="12">
        <h1  style="font-size: 40px" class="d-none d-md-block text-center">{{title}}</h1>
        <h1  class="d-md-none text-center">{{title}}</h1>
      </v-col>
    </v-row>
    <PageText :text="p1" />
    <PageText :text="p2" />
    <a href="https://www.emdria.org/about-emdr-therapy/">EMDR Info</a>
    <br/>
    <br/>
    <PageQuote :text="q1" person="Francine Shapiro" />
  </v-container>
</template>

<script>
import PageText from '@/components/PageText.vue';
import PageQuote from '@/components/PageQuote.vue';

export default {
  components: { PageText, PageQuote },
  data: () => ({
    title: 'Eye Movement Desensitization Reprocessing',
    p1: `
     Eye Movement Desensitization Reprocessing (EMDR) is a therapeutic modality primarily for the
     treatment of trauma. EMDR uses what is referred to as bilateral stimulation to assist in
     reprocessing distressing memories. This resumes the natural healing mechanisms in the brain.
     With this approach, clients have the ability to address painful experiences without being
     forced to talk extensively of their memories. While EMDR is often discussed in the context of
     trauma treatment, it can be used for the treatment of many issues. I have found EMDR to be an
     effective approach in addressing phobias, panic disorder, performance anxiety, and issues
     pertaining to grief and loss. I have completed continuing education to be able to work with
     clients who have issues with dissociation, and clients with minimal ability to tolerate
     distress. 
    `,
    p2: `
      To find more information on EMDR therapy, visit the EMDR International Association (EMDRIA) website:
    `,
    q1: `
      "Changing the memories that form the way we see ourselves also change the way we view others.
      Therefore, our relationships, job performance, what we are willing to do or are able to
      resist, all move in a positive direction.” 
    `,
  }),
};
</script>
